import React from 'react'
import ServiceCard from '../../components/ServiceCard';
import { HiQuestionMarkCircle } from "react-icons/hi";

const services = [
    {
      title: '',
      description: "At RFI International Trade, customer satisfaction and success are our top priorities. We tailor solutions to meet the unique needs of each client, helping businesses gain a competitive advantage in the global market. Our expert team, with years of experience and industry knowledge, strives to deliver the best service possible.",
      icon: <HiQuestionMarkCircle className="w-8 h-8" />
    }
  ]

function WhyUs() {
  return (
      <section id="whyUs" className="py-12 bg-gray-100">
        <div className="container mx-auto px-4">
        <h2 className="text-3xl text-primary-light font-bold text-center mb-8">Why Us?</h2>
        <ServiceCard 
        title={services[0].title} 
        description={services[0].description}
        icon={services[0].icon}/>
        </div>
      </section>
  )
}

export default WhyUs