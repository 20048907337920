import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from 'react-icons/fi';

function Footer() {
  return (
    <footer className="bg-primary-light text-white p-6 mt-auto">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          <h5 className="text-lg font-bold mb-2">Contact Us</h5>
          <p>Yunus Emre Yucekaya</p>
          <p>+1 (201) 381-8313 </p>
          <p className='mb-3'>emre@rfitrade.com</p>

          <p>Inanc Yildiz</p>
          <p>+1 (646) 229 9889  </p>
          <p>yildiz@rfitrade.com </p>
        </div>
        <div className="flex space-x-4 mb-4 md:mb-0">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook" className="text-white hover:text-gray-300 transition-colors duration-300">
            <FiFacebook size={24} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter" className="text-white hover:text-gray-300 transition-colors duration-300">
            <FiTwitter size={24} />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram" className="text-white hover:text-gray-300 transition-colors duration-300">
            <FiInstagram size={24} />
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" className="text-white hover:text-gray-300 transition-colors duration-300">
            <FiLinkedin size={24} />
          </a>
        </div>
        <div className="text-center md:text-right">
          <p>&copy; 2024 RFI International Trade. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
