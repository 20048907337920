import React from 'react';
import ServiceCard from '../../components/ServiceCard';
import { FaChartLine, FaGavel, FaTruck, FaFileInvoiceDollar } from 'react-icons/fa'; 

const services = [
  {
    title: 'Market Entry Strategies',
    description: 'Guidance on developing and implementing strategies for entering new markets.',
    icon: <FaChartLine className="w-8 h-8" />
  },
  {
    title: 'Trade Regulations',
    description: 'Providing information and consultancy on current international trade regulations and legal requirements.',
    icon: <FaGavel className="w-8 h-8" />
  },
  {
    title: 'Supply Chain Management',
    description: 'Optimization and improvement recommendations for global supply chain management.',
    icon: <FaTruck className="w-8 h-8" />
  },
  {
    title: 'Customs Procedures',
    description: 'Detailed information and assistance regarding customs processes and procedures.',
    icon: <FaFileInvoiceDollar className="w-8 h-8" />
  },
  {
    title: 'Foreign Trade Financing',
    description: 'Offering suitable financing solutions for export and import transactions.',
    icon: <FaFileInvoiceDollar className="w-8 h-8" />
  }
];

const Services = () => {
  return (
    <section id="services" className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl text-primary-light font-bold text-center mb-8">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              description={service.description}
              icon={service.icon}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
