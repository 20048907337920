import React from 'react';

const ServiceCard = ({ title, description, icon }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="p-4 flex items-center space-x-4">
        <div className="text-primary-light">{icon}</div>
        <div>
          <h3 className="text-xl font-semibold">{title}</h3>
          <p className="mt-2 text-gray-600">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
