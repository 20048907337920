import React from 'react';
import Body from '../sections/Home/Body.js';
import Header from '../sections/Home/Header.js';
import Footer from '../sections/Home/Footer.js';
import Slider from '../sections/Home/Slider.js';
import Services from '../sections/Home/Services.js';
import WhatWeDo from '../sections/Home/WhatWeDo.js';
import WhyUs from '../sections/Home/WhyUs.js';
import Conclusion from '../sections/Home/Conclusion.js';
import Products from '../sections/Home/Products.js';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Home = () => {
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref5, inView5] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref6, inView6] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref7, inView7] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <motion.div
        ref={ref1}
        initial={{ opacity: 0, y: 50 }}
        animate={inView1 ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        <Slider />
      </motion.div>
      <motion.div
        ref={ref2}
        initial={{ opacity: 0, y: 50 }}
        animate={inView2 ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        <WhatWeDo />
      </motion.div>
      <motion.div
        ref={ref3}
        initial={{ opacity: 0, y: 50 }}
        animate={inView3 ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        <Services />
      </motion.div>
      <motion.div
        ref={ref4}
        initial={{ opacity: 0, y: 50 }}
        animate={inView4 ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        <Products />
      </motion.div>
      <motion.div
        ref={ref5}
        initial={{ opacity: 0, y: 50 }}
        animate={inView5 ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        <Body />
      </motion.div>
      <motion.div
        ref={ref6}
        initial={{ opacity: 0, y: 50 }}
        animate={inView6 ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        <WhyUs />
      </motion.div>
      <motion.div
        ref={ref7}
        initial={{ opacity: 0, y: 50 }}
        animate={inView7 ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 1 }}
      >
        <Conclusion />
      </motion.div>
      <Footer />
    </div>
  );
};

export default Home;
