import React from 'react';
import { Link } from 'react-scroll';
//import { useNavigate } from "react-router-dom";

function Header() {
  //const navigate = useNavigate();

  /*
  const handleClick = () => {
      navigate("/products");
  }
  */

  return (
    <header className="bg-primary-light text-white p-4">
      <nav className="container mx-auto flex items-center justify-between overflow-x-auto whitespace-nowrap">
        <div className="flex flex-grow items-center justify-center space-x-6 md:space-x-24">
        <Link
            to="/"
            smooth={true}
            duration={500}
            className="text-gray-300 font-semibold cursor-pointer transition-colors duration-300 hover:text-primary-dark"
          >
            Home Page
          </Link>
        <Link
            to="whatWeDo"
            smooth={true}
            duration={500}
            className="text-gray-300 font-semibold cursor-pointer transition-colors duration-300 hover:text-primary-dark"
          >
            What We Do?
          </Link>
          <Link
            to="services"
            smooth={true}
            duration={500}
            className="text-gray-300 font-semibold cursor-pointer transition-colors duration-300 hover:text-primary-dark"
          >
            Services
          </Link>
          <Link
            to="products"
            smooth={true}
            duration={500}
            className="text-white font-extrabold cursor-pointer transition-colors duration-300 hover:text-primary-dark"
          >
            Products
          </Link>
          <Link
            to="importance"
            smooth={true}
            duration={500}
            className="text-gray-300 font-semibold cursor-pointer transition-colors duration-300 hover:text-primary-dark"
          >
            Importance
          </Link>
          <Link
            to="whyUs"
            smooth={true}
            duration={500}
            className="text-gray-300 font-semibold cursor-pointer transition-colors duration-300 hover:text-primary-dark"
          >
            Why Us?
          </Link>
          <Link
            to="conclusion"
            smooth={true}
            duration={500}
            className="text-gray-300 font-semibold cursor-pointer transition-colors duration-300 hover:text-primary-dark"
          >
            Conclusion
          </Link>
        </div>
      </nav>
    </header>
  );
}

export default Header;
