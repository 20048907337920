import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import slider1 from '../../assets/img/slider3.jpg'
import slider2 from '../../assets/img/slider4.jpeg'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../../myswiper.css';

const Slider = () => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={2} 
      slidesPerView={1} 
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      //onSwiper={(swiper) => console.log(swiper)}
      //onSlideChange={() => console.log('slide change')}
      className="mySwiper" 
    >
      <SwiperSlide>
        <img src={slider2} alt="Slide 1" className="w-full h-auto object-cover" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slider1} alt="Slide 2" className="w-full h-auto object-cover" />
      </SwiperSlide>
    </Swiper>
  );
};

export default Slider;
