import React from 'react'
import ServiceCard from '../../components/ServiceCard';
import { PiNotepadFill } from "react-icons/pi";

const services = [
    {
      title: '',
      description: "Success in international trade requires the right strategies and solid consultancy support. At RFI International Trade, we take pride in offering reliable and professional consultancy services that help businesses maximize their growth potential in the global market. Partnering with us can help businesses turn challenges in international trade into opportunities for sustainable growth.",
      icon: <PiNotepadFill className="w-8 h-8" />
    }
  ]

function Conclusion() {
  return (
      <section id="conclusion" className="py-12 bg-gray-100">
        <div className="container mx-auto px-4">
        <h2 className="text-3xl text-primary-light font-bold text-center mb-8">Conclusion</h2>
        <ServiceCard 
        title={services[0].title} 
        description={services[0].description}
        icon={services[0].icon}/>
        </div>
      </section>
  )
}

export default Conclusion