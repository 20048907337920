import React from 'react';

function Header({ selectedCategory, setSelectedCategory }) {
  return (
    <header className="bg-primary-light text-white p-4">
      <nav className="container mx-auto flex items-center justify-between overflow-x-auto whitespace-nowrap">
        <div className="flex flex-grow items-center justify-center space-x-6 md:space-x-32">
          <a
            href="/"
            smooth={true}
            duration={500}
            className="text-white font-extrabold cursor-pointer transition-colors duration-300 hover:text-primary-dark"
          >
            Home Page
          </a>
          <div
            onClick={() => setSelectedCategory('Category 1')}
            className={`text-white font-semibold cursor-pointer transition-colors duration-300 hover:text-primary-dark ${selectedCategory === 'Category 1' && 'text-blue-300'}`}
          >
            Category 1
          </div>
          <div
            onClick={() => setSelectedCategory('Category 2')}
            className={`text-white font-semibold cursor-pointer transition-colors duration-300 hover:text-primary-dark ${selectedCategory === 'Category 2' && 'text-blue-300'}`}
          >
            Category 2
          </div>
          <div
            onClick={() => setSelectedCategory('Category 3')}
            className={`text-white font-semibold cursor-pointer transition-colors duration-300 hover:text-primary-dark ${selectedCategory === 'Category 3' && 'text-blue-300'}`}
          >
            Category 3
          </div>
          <div
            onClick={() => setSelectedCategory('Category 4')}
            className={`text-white font-semibold cursor-pointer transition-colors duration-300 hover:text-primary-dark ${selectedCategory === 'Category 4' && 'text-blue-300'}`}
          >
            Category 4
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
