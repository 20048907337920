import React, { useState } from 'react';
import Header from '../sections/Product/Header';
import Body from '../sections/Product/Body';

function Products() {
  const [selectedCategory, setSelectedCategory] = useState('Category 1');

  return (
    <div>
      <Header selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
      <Body selectedCategory={selectedCategory} />
    </div>
  );
}

export default Products;
