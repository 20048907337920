import React from 'react';
import { motion } from 'framer-motion';

const ProductModal = ({ isOpen, onClose, product }) => {
  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50"
    >
      <div className="bg-white p-4 md:p-6 rounded-lg max-w-4xl w-full relative overflow-y-auto max-h-screen">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-900 text-2xl"
        >
          &times;
        </button>
        <h2 className="text-xl md:text-2xl font-bold mb-4">{product.name}</h2>
        <div className="flex flex-wrap gap-4 overflow-x-auto">
          <img 
            src={product.image} 
            alt={product.name} 
            className="w-full md:w-1/3 h-auto object-cover rounded-lg" 
          />
          {product.extraImages && product.extraImages.map((img, index) => (
            <img 
              key={index} 
              src={img} 
              alt={`Additional ${index}`} 
              className="w-full md:w-1/3 h-auto object-cover rounded-lg" 
            />
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default ProductModal;
