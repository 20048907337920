import React from 'react';
import ProductCard from '../../components/ProductCard.js';

const products = [
  {
    image: 'https://via.placeholder.com/150',
    name: 'Product 1',
    description: 'This is the description for product 1.',
    price: '$49.99',
    category: 'Category 1'
  },
  {
    image: 'https://via.placeholder.com/150',
    name: 'Product 2',
    description: 'This is the description for product 2.',
    price: '$59.99',
    category: 'Category 2'
  },
  {
    image: 'https://via.placeholder.com/150',
    name: 'Product 3',
    description: 'This is the description for product 3.',
    price: '$69.99',
    category: 'Category 3'
  },
  {
    image: 'https://via.placeholder.com/150',
    name: 'Product 4',
    description: 'This is the description for product 4.',
    price: '$79.99',
    category: 'Category 4'
  }
];

const Body = ({ selectedCategory }) => {
  const filteredProducts = products.filter(product => product.category === selectedCategory);

  return (
    <div className="container mx-auto p-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
      {filteredProducts.map((product, index) => (
        <ProductCard
          key={index}
          image={product.image}
          name={product.name}
          description={product.description}
          price={product.price}
        />
      ))}
    </div>
  );
};

export default Body;
