import React from 'react';
import ServiceCard from '../../components/ServiceCard';
import { MdNotificationImportant } from "react-icons/md";

const services = [
  {
    title: '',
    description: "International trade is a critical process that enables businesses to reach new markets and opportunities beyond borders. However, it comes with various challenges such as different trade norms, customs procedures, tax regulations, and cultural factors among different countries. This highlights the significant importance of well-guided consultancy services tailored to address these complexities.",
    icon: <MdNotificationImportant className="w-8 h-8" />
  }
]

function Body() {
  return (
    <section id="importance" className="py-12 bg-gray-100">
    <div className="container mx-auto px-4">
    <h2 className="text-3xl text-primary-light font-bold text-center mb-8">The Importance and Challenges of International Trade</h2>
    <ServiceCard 
    title={services[0].title} 
    description={services[0].description}
    icon={services[0].icon}/>
    </div>
  </section>
  );
}

export default Body;
