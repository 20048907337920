import React from 'react';

const ProductCard = ({ image, name, description, price, onClick }) => {
  return (
    <div
      className="bg-white shadow-md rounded-lg overflow-hidden cursor-pointer"
      onClick={onClick}
    >
      <img src={image} alt={name} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h2 className="text-lg font-bold mb-2 text-center">{name}</h2>
        <p className="text-gray-700 mb-4">{description}</p>

        { /* <div className="flex items-center justify-between">
          <span className="text-xl font-extrabold">{price}</span>
          <button className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-300">
            Buy Now
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ProductCard;
