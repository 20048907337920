import React from 'react'
import ServiceCard from '../../components/ServiceCard';
import { HiQuestionMarkCircle } from "react-icons/hi";

const services = [
    {
      title: '',
      description: "At RFI International Trade, we offer comprehensive consultancy services to help you navigate the complexities of international trade. We provide strategic guidance and expertise for businesses aiming to achieve success and growth in the global market.",
      icon: <HiQuestionMarkCircle className="w-8 h-8" />
    }
  ]

function WhatWeDo() {
  return (
      <section id="whatWeDo" className="py-12 bg-gray-100">
        <div className="container mx-auto px-4">
        <h2 className="text-3xl text-primary-light font-bold text-center mb-8">What We Do?</h2>
        <ServiceCard 
        title={services[0].title} 
        description={services[0].description}
        icon={services[0].icon}/>
        </div>
      </section>
  )
}

export default WhatWeDo